<template>
  <div>
    <a-page-header
      title="Новое в Реестре"
    />
    <a-spin 
      :spinning="loading"
      size="large"
    >
      <div>
        <div class="reestrNews" v-if="news">
          <articleRegistryCard 
            v-for="article in news.data" 
            :key="article.id"
            :article="article"
          />
        </div>
        <a-pagination 
          v-if="news"
          class="text-right"
          :defaultPageSize="20"
          :total="news.total"
          :showTotal="(total, range) => `${range[0]}-${range[1]} из ${total}`"
          v-model:current="currentPage"
          @change="loadNews"
        />
      </div>
    </a-spin>
  </div>
</template>

<script>
import { ref, onMounted} from 'vue'
import articleRegistryCard from "@/components/sp-articles/articleRegistryCard"
import api from '@/api';
export default {
  name: "Homepage",
  components: { articleRegistryCard },
  setup() {
    const news = ref(null);
    const currentPage = ref(1);
    const loading = ref(false);

    const loadNews = async(page) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      loading.value = true;
      try {
        news.value = await api.registrynews.getRegistryNews(page);
      }
      finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      await loadNews(currentPage.value);
    });

    return {
      news,
      currentPage,
      loadNews,
      loading
    }
  }
}
</script>

<style lang="scss">
.ant-page-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;

  & .ant-page-header-heading-title {
    font-size: 2rem;
    font-weight: 700;
  }
}

.reestrNews {
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1rem;
}
</style>