<template>
  <div class="articleHero">
    <router-link :to="{ name: 'SingleNews',  params: { id: article.id }}">
      <span class="mask"></span>
      <div class="meta">
        <span class="date">{{ moment(article.date).format('DD.MM.YYYY') }}</span>
        <div>
          <span class="title">{{ article.title }}</span>
          <span class="description">{{ article.previewText }}</span>
        </div>
      </div>
      <img
        :src="article.pictureURL"
      />
    </router-link>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ArticleHero',
  props: {
    article: {
      type: Object,
      default: null
    },
    meta: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      moment
    }
  }
}
</script>

<style lang="scss">
.articleHero {
  position: relative;
  margin-bottom: 2rem;

  & a {
    color: #ffffff;
    width: 100%;
    height: 100%;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
      max-height: 417px;
    }

    & .meta {
      position: absolute;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      & > div {
        display: flex;
        flex-direction: column;
        width: 50%;
      }

      & .date, .description {
        font-size: 18px;
      }

      & .title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 1rem;
      }
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .5);
      border-radius: 5px;
    }
  }
}
</style>