<template>
  <div v-if="news" class="singlePage">
    <div class="mainColumn">
    <div class="meta">
      <h1>
        {{ news.title }}
      </h1>
      <div class="date">
        {{ moment(news.date).format('DD.MM.YYYY') }}
      </div>
    </div>
    
    <div class="content">
      <div v-html="news.description"></div>
    </div>
  </div>
  </div>
</template>

<script>
import moment from 'moment';
import {ref, onMounted} from 'vue'
import api from '@/api';
import { useRoute } from 'vue-router'

export default {
  name: "SingleRegistryNewsPage",
  components: {},
  setup() {
    const news = ref(null);
    const route = useRoute();

    onMounted(async () => {
      const id = route.params.id
      news.value = await api.registrynews.getSingleRegistryNews(id);
    });
    return {
      news,
      moment
    }
  }
}
</script>