<template>
  <div>
    <a-page-header
      title="Все новости"
    />
    <div v-if="heronews">
      <article-hero :article="heronews" />
    </div>
    <a-spin 
      :spinning="loading"
      size="large"
    >
      <div>
        <div class="listNews" v-if="news">
          <article-card 
            v-for="article in news.data" 
            :key="article.id"
            :article="article"
            :showCover="true"
          />
          <a-pagination 
            class="text-right"
            :total="news.total"
            :showTotal="(total, range) => `${range[0]}-${range[1]} из ${total}`"
            v-model:current="currentPage"
            @change="loadNews"
          />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { ref, onMounted} from 'vue'
import articleHero from '@/components/sp-articles/articleHero'
import articleCard from '@/components/sp-articles/articleCard'
import api from '@/api';
export default {
  name: "Homepage",
  components: { articleHero, articleCard },
  setup() {
    const news = ref(null);
    const heronews = ref(null);
    const currentPage = ref(1);
    const loading = ref(false);

    const loadNews = async(page) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      loading.value = true;
      try {
        news.value = await api.news.getNews(page);
      }
      finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      await loadNews(currentPage.value);
      heronews.value = await api.news.getHeroNews();
    });
    return {
      news,
      heronews,
      currentPage,
      loadNews,
      loading
    }
  }
}
</script>

<style lang="scss">
.ant-page-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;

  & .ant-page-header-heading-title {
    font-size: 2rem;
    font-weight: 700;
  }
}

.listNews {
  margin-bottom: 2rem;
  display: grid;
  grid-gap: 1rem;
}
</style>