<template>
  <div v-if="news" class="singlePage">
    <div class="mainColumn">
      <div class="meta">
        <h1>
          {{ news.title }}
        </h1>
        <div class="date">
          {{ moment(news.date).format('DD.MM.YYYY') }}
        </div>
      </div>
      <div class="content">
        <div v-html="news.description"></div>
      </div>
    </div>
    <div class="rightColumn">
      <div class="widget listNews" v-if="otherNews">
        <article-card
          v-for="article in otherNews"
          :key="article.id"
          :article="article"
          :showCover="true"
        />
        <div class="allnews-link">
          <router-link to="/allNews">Все новости</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {ref, onMounted} from 'vue'
import api from '@/api';
import {useRoute} from 'vue-router'
import articleCard from '@/components/sp-articles/articleCard'

export default {
  name: "SingleNewsPage",
  components: {articleCard},
  setup() {
    const news = ref(null);
    const route = useRoute();
    const otherNews = ref(null);

    onMounted(async () => {
      const id = route.params.id
      news.value = await api.news.getSingleNews(id);
      otherNews.value = await api.news.getNews();
      otherNews.value = otherNews.value.filter(value => value.id != id);
      otherNews.value = otherNews.value.slice(0,3);
    });
    return {
      news,
      otherNews,
      moment
    }
  }
}
</script>